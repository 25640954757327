<template>
  <div>
    <div v-if="!loader">
      <Main class="footer">
        <a-row :gutter="25">
          <a-col :lg="4" :xl="7" :md="10" :xs="24">
            <h3 style="padding-top: 40px; padding-left: 20px; color: #6d6868; font-weight: bold">Billing & Shipping</h3>
          </a-col>
        </a-row>

        <a-row :gutter="25">
          <a-col :lg="16" :xl="23" :md="24" :xs="24">
            <BasicFormWrapper class="card">
              <VerticalFormStyleWrap>
                <sdCards :header="false">
                  <a-row :gutter="25">
                    <a-col :lg="12" :xl="8" :md="12" :xs="24">
                      <h2>Account Information</h2>
                    </a-col>
                  </a-row>

                  <a-row :gutter="25" style="padding-top: 40px">
                    <a-col :lg="8" :xl="8" :md="8" :xs="24">
                      <a-row>
                        <h4>*First Name</h4>
                      </a-row>

                      <a-row>
                        <a-form-item>
                          <a-input
                            class="input"
                            name="firstname"
                            v-model:value="firstName"
                            placeholder="Enter Your First Name"
                            disabled
                          />
                        </a-form-item>
                      </a-row>
                    </a-col>

                    <a-col :lg="8" :xl="8" :md="8" :xs="24">
                      <a-row>
                        <h4>*Last Name</h4>
                      </a-row>

                      <a-row>
                        <a-form-item>
                          <a-input
                            class="input"
                            name="lastname"
                            v-model:value="lastName"
                            placeholder="Enter Your Last Name"
                            disabled
                          />
                        </a-form-item>
                      </a-row>
                    </a-col>
                  </a-row>

                  <a-row :gutter="25" style="padding-top: 40px">
                    <a-col :lg="8" :xl="8" :md="8" :xs="24">
                      <a-row>
                        <h4>*Phone</h4>
                      </a-row>

                      <a-row>
                        <a-form-item>
                          <a-input
                            class="input"
                            name="phone"
                            v-model:value="phone"
                            placeholder="Enter Your Phone Number"
                          />
                        </a-form-item>
                      </a-row>
                    </a-col>

                    <a-col :lg="8" :xl="8" :md="8" :xs="24">
                      <a-row>
                        <h4>*Gender</h4>
                      </a-row>

                      <a-row>
                        <a-form-item>
                          <a-input
                            class="input"
                            name="gender"
                            v-model:value="gender"
                            placeholder="Enter Your Gender"
                            disabled
                          />
                        </a-form-item>
                      </a-row>
                    </a-col>
                  </a-row>

                  <a-row :gutter="25" style="padding-top: 40px">
                    <a-col :lg="8" :xl="8" :md="8" :xs="24">
                      <a-row>
                        <h4>*Email</h4>
                      </a-row>

                      <a-row>
                        <a-form-item>
                          <a-input
                            class="input"
                            name="email"
                            v-model:value="email"
                            placeholder="Enter Your Email"
                            disabled
                          />
                        </a-form-item>
                      </a-row>
                    </a-col>

                    <a-col :lg="8" :xl="8" :md="8" :xs="24">
                      <a-row>
                        <h4>*Password</h4>
                      </a-row>

                      <a-row>
                        <a-form-item>
                          <a-input
                            class="input"
                            name="password"
                            placeholder="*******"
                            @click="openPasswordModal"
                            readonly
                          />
                        </a-form-item>

                        <span style="padding-left: 20px; padding-top: 20px" @click="openPasswordModal">
                          <sdFeatherIcons color="#C5C5C5" type="edit" />
                        </span>
                      </a-row>
                    </a-col>
                  </a-row>

                  <a-row :gutter="25" style="padding-top: 100px">
                    <a-col :lg="12" :xl="8" :md="12" :xs="24">
                      <h2>Billing & Shipping</h2>
                    </a-col>
                  </a-row>

                  <a-row :gutter="25">
                    <a-col :lg="20" :xl="20" :md="12" :xs="24" style="padding-top: 40px">
                      <a-row>
                        <h4>*Card Information</h4>
                      </a-row>

                      <a-row>
                        <a-form-item v-if="!changeCreditCardDetails" style="border: 2px solid #0ca2ad">
                          <img class="icon" v-if="(cardBrand !== 'visa' && cardBrand !== 'mastercard' && cardBrand !== 'jcb' && cardBrand !== 'amex' && cardBrand !== 'discover' && cardBrand !== 'dinersclub' )" src="https://raw.githubusercontent.com/aaronfagan/svg-credit-card-payment-icons/master/mono/generic.svg?sanitize=true" alt="Generic Card">
                          <img class="icon" v-if="cardBrand == 'visa'" src="https://raw.githubusercontent.com/aaronfagan/svg-credit-card-payment-icons/master/flat/visa.svg?sanitize=true" alt="Generic Card">
                          <img class="icon" v-if="cardBrand == 'mastercard'" src="https://raw.githubusercontent.com/aaronfagan/svg-credit-card-payment-icons/master/flat/mastercard.svg?sanitize=true" alt="Generic Card">
                          <img class="icon" v-if="cardBrand == 'jcb'" src="https://raw.githubusercontent.com/aaronfagan/svg-credit-card-payment-icons/master/flat/jcb.svg?sanitize=true" alt="Generic Card">
                          <img class="icon" v-if="cardBrand == 'amex'" src="https://raw.githubusercontent.com/aaronfagan/svg-credit-card-payment-icons/master/flat/amex.svg?sanitize=true" alt="Generic Card">
                          <img class="icon" v-if="cardBrand == 'discover'" src="https://raw.githubusercontent.com/aaronfagan/svg-credit-card-payment-icons/master/flat/discover.svg?sanitize=true" alt="Generic Card">
                          <img class="icon" v-if="cardBrand == 'dinersclub'" src="https://raw.githubusercontent.com/aaronfagan/svg-credit-card-payment-icons/master/flat/diners.svg?sanitize=true" alt="Generic Card">

                          <span style="padding-left: 10px">{{ hiddenCardInfo }}</span
                          ><span style="padding-left: 20px">{{ hiddenCardDate }}</span>
                          <span style="padding-left: 10px; padding-right: 10px">{{ hiddenCardCvc }}</span>
                        </a-form-item>
                        <span
                          v-if="!changeCreditCardDetails"
                          style="padding-left: 20px; padding-top: 20px"
                          @click="showCreditCard"
                        >
                          <sdFeatherIcons color="#C5C5C5" type="edit" />
                        </span>
                        <a-form-item v-if="changeCreditCardDetails">
                          <!-- <payment-card :api-prop="apiData" class="cardnmb" style="width: 350px" /> -->
                          <stripe-field :apiData="apiData" class="cardnmb" style="width: 400px" />
                          <a-form-item style="width: 400px">
                              <a-input
                                class="input"
                                name="email"
                                placeholder="Enter Your Card Holder Name"
                                v-model:value="cardHolderName"

                              />
                            </a-form-item>
                          <sdButton
                            class="btn-signin"
                            @click="changePaymentDetail"
                            size="small"
                            style="background-color: gray"
                            htmlType="button"
                            type="primary"
                          >
                            Cancel
                          </sdButton>
                        </a-form-item>
                      </a-row>
                    </a-col>
                  </a-row>
                  <a-row>
                    <a-col :lg="20" :xl="20" :md="20" :xs="24" style="padding-top: 40px">
                      <a-row>
                        <h4>*Billing Address</h4>
                      </a-row>

                      <a-row>
                        <a-col :lg="8" :xl="8" :md="8" :xs="24">
                          <a-row>
                            <h4 style="padding: 15px">Street</h4>

                            <a-form-item>
                              <a-input
                                class="input"
                                name="email"
                                v-model:value="billingAddress.street"
                                placeholder="Enter Your Email"
                              />
                            </a-form-item>
                          </a-row>
                        </a-col>
                        <a-col :lg="8" :xl="8" :md="8" :xs="24">
                          <a-row>
                            <h4 style="padding: 15px">State</h4>

                            <a-form-item>
                              <a-input
                                class="input"
                                name="email"
                                v-model:value="billingAddress.state"
                                placeholder="Enter Your Email"
                              />
                            </a-form-item>
                          </a-row>
                        </a-col>
                        <!-- <a-form-item > -->
                        <!-- <span class="locationicon">
                                     <sdFeatherIcons color="#C5C5C5" class="location" type="map-pin" />
                                   </span> -->
                        <!-- <GMapAutocomplete
                                    class="map"
                                    placeholder="Enter Your Address here"
                                    style="width: 100%"
                                    v-model:value="address"
                                     @place_changed="setPlace"
                                  >
                                  </GMapAutocomplete>
                                  <GMapMap
                                      :center="center"
                                  v-if="false"

                                      :zoom="7"
                                      map-type-id="terrain"
                                      style="width: 1000px; height: 300px"
                                  >
                                  </GMapMap> -->
                        <!-- </a-form-item> -->
                      </a-row>
                    </a-col>
                  </a-row>
                  <a-row>
                    <a-col :lg="20" :xl="20" :md="20" :xs="24" style="padding-top: 40px">
                      <a-row>
                        <a-col :lg="8" :xl="8" :md="8" :xs="24">
                          <a-row>
                            <h4 style="padding: 15px">City</h4>

                            <a-form-item style="padding-left: 15px">
                              <a-input
                                class="input"
                                name="email"
                                v-model:value="billingAddress.city"
                                placeholder="Enter Your City"
                              />
                            </a-form-item>
                          </a-row>
                        </a-col>
                        <a-col :lg="8" :xl="8" :md="8" :xs="24">
                          <a-row>
                            <h4 style="padding: 15px">Zip</h4>

                            <a-form-item style="padding-left: 15px">
                              <a-input
                                class="input"
                                name="email"
                                v-model:value="billingAddress.zip"
                                placeholder="Enter Your Email"
                              />
                            </a-form-item>
                          </a-row>
                        </a-col>
                        <!-- <a-form-item > -->
                        <!-- <span class="locationicon">
                                     <sdFeatherIcons color="#C5C5C5" class="location" type="map-pin" />
                                   </span> -->
                        <!-- <GMapAutocomplete
                                    class="map"
                                    placeholder="Enter Your Address here"
                                    style="width: 100%"
                                    v-model:value="address"
                                     @place_changed="setPlace"
                                  >
                                  </GMapAutocomplete>
                                  <GMapMap
                                      :center="center"
                                  v-if="false"

                                      :zoom="7"
                                      map-type-id="terrain"
                                      style="width: 1000px; height: 300px"
                                  >
                                  </GMapMap> -->
                        <!-- </a-form-item> -->
                      </a-row>
                    </a-col>
                  </a-row>
                  <!-- shipping address section -->
                  <a-row>
                    <a-col :lg="20" :xl="20" :md="20" :xs="24" style="padding-top: 40px">
                      <a-row>
                        <h4>*Shipping Address</h4>
                      </a-row>

                      <a-row>
                        <a-col :lg="8" :xl="8" :md="8" :xs="24">
                          <a-row>
                            <h4 style="padding: 15px">Address 1</h4>

                            <a-form-item>
                              <a-input
                                class="input"
                                name="email"
                                v-model:value="shipingAddress.street"
                                placeholder="Enter Your Address"
                              />
                            </a-form-item>
                          </a-row>
                        </a-col>
                        <a-col :lg="8" :xl="8" :md="8" :xs="24">
                          <a-row>
                            <h4 style="padding: 15px">Address 2</h4>
                            <a-form-item>
                              <a-input
                                class="input"
                                name="email"
                                v-model:value="shipingAddress.address2"
                                placeholder="Enter Your Address"
                              />
                            </a-form-item>
                            
                          </a-row>
                        </a-col>
                        <!-- <a-form-item > -->
                        <!-- <span class="locationicon">
                                     <sdFeatherIcons color="#C5C5C5" class="location" type="map-pin" />
                                   </span> -->
                        <!-- <GMapAutocomplete
                                    class="map"
                                    placeholder="Enter Your Address here"
                                    style="width: 100%"
                                    v-model:value="address"
                                     @place_changed="setPlace"
                                  >
                                  </GMapAutocomplete>
                                  <GMapMap
                                      :center="center"
                                  v-if="false"

                                      :zoom="7"
                                      map-type-id="terrain"
                                      style="width: 1000px; height: 300px"
                                  >
                                  </GMapMap> -->
                        <!-- </a-form-item> -->
                      </a-row>
                    </a-col>
                  </a-row>
                  <a-row>
                    <a-col :lg="20" :xl="20" :md="20" :xs="24" style="padding-top: 40px">
                      <a-row>
                        <a-col :lg="8" :xl="8" :md="8" :xs="24">
                          <a-row>
                            <h4 style="padding: 15px">City</h4>

                            <a-form-item style=" padding-left:40px">
                              <a-input
                                class="input"
                                name="email"
                                v-model:value="shipingAddress.city"
                                placeholder="Enter Your City"
                                
                              />
                            </a-form-item>
                          </a-row>
                        </a-col>
                        <a-col :lg="8" :xl="8" :md="8" :xs="24">
                          <a-row>
                            <h4 style="padding: 15px;">Zip</h4>

                            <a-form-item style=" padding-left:45px">
                              <a-input
                                class="input"
                                name="email"
                                v-model:value="shipingAddress.zip"
                                placeholder="Enter Your ZIP"
                              />
                            </a-form-item>
                          </a-row>
                        </a-col>
                        <!-- <a-form-item > -->
                        <!-- <span class="locationicon">
                                     <sdFeatherIcons color="#C5C5C5" class="location" type="map-pin" />
                                   </span> -->
                        <!-- <GMapAutocomplete
                                    class="map"
                                    placeholder="Enter Your Address here"
                                    style="width: 100%"
                                    v-model:value="address"
                                     @place_changed="setPlace"
                                  >
                                  </GMapAutocomplete>
                                  <GMapMap
                                      :center="center"
                                  v-if="false"

                                      :zoom="7"
                                      map-type-id="terrain"
                                      style="width: 1000px; height: 300px"
                                  >
                                  </GMapMap> -->
                        <!-- </a-form-item> -->
                      </a-row>
                    </a-col>
                  </a-row>
                  <a-row>
                    <a-col :lg="20" :xl="20" :md="20" :xs="24" style="padding-top: 40px">
                      <a-row>
                        <a-col :lg="8" :xl="8" :md="8" :xs="24">
                          <a-row>
                            <h4 style="padding: 15px">State</h4>

                          <a-form-item style=" padding-left:32px">
                              <a-input
                                class="input"
                                name="email"
                                v-model:value="shipingAddress.state"
                                placeholder="Enter Your State"
                              />
                            </a-form-item>
                          </a-row>
                        </a-col>
                       
                        <!-- <a-form-item > -->
                        <!-- <span class="locationicon">
                                     <sdFeatherIcons color="#C5C5C5" class="location" type="map-pin" />
                                   </span> -->
                        <!-- <GMapAutocomplete
                                    class="map"
                                    placeholder="Enter Your Address here"
                                    style="width: 100%"
                                    v-model:value="address"
                                     @place_changed="setPlace"
                                  >
                                  </GMapAutocomplete>
                                  <GMapMap
                                      :center="center"
                                  v-if="false"

                                      :zoom="7"
                                      map-type-id="terrain"
                                      style="width: 1000px; height: 300px"
                                  >
                                  </GMapMap> -->
                        <!-- </a-form-item> -->
                      </a-row>
                    </a-col>
                  </a-row>
                  <!-- shipping address section end -->
                  <a-row :gutter="25" style="padding-top: 40px">
                    <a-col :lg="4" :xl="4" :md="4" :xs="24">
                      <a-row>
                        <sdButton
                          class="btn-signin"
                          size="small"
                          style="background-color: #0db3b5; width: 150px !important; margin-bottom: 20px"
                          htmlType="button"
                          type="primary"
                          @click="updateInfo"
                        >
                          {{ accountsSave ? 'Updating...' : 'Save Changes' }}
                        </sdButton>
                      </a-row>
                    </a-col>
                  </a-row>
                  <a-modal :visible="visible" @ok="handleOk" @cancel="handleCancel">
                    <h2 class="modal-header">Change Password</h2>
                    <a-row>
                      <h4>*Current Password</h4>
                    </a-row>

                    <a-row>
                      <a-form-item>
                        <a-input
                          class="input"
                          name="password"
                          placeholder="Enter Your Current Password"
                          v-model:value="currentPassword"
                        />
                      </a-form-item>
                    </a-row>
                    <a-row>
                      <h4>*New Password</h4>
                    </a-row>
                    <a-row>
                      <a-form-item>
                        <a-input
                          class="input"
                          name="password"
                          placeholder="Enter Your New Password"
                          v-model:value="newPassword"
                        />
                      </a-form-item>
                    </a-row>
                    <a-row>
                      <h4>*Confirm Password</h4>
                    </a-row>
                    <a-row>
                      <a-form-item>
                        <a-input
                          class="input"
                          name="password"
                          placeholder="Confirm Your New Password"
                          v-model:value="confirmPassword"
                        />
                      </a-form-item>
                    </a-row>
                    <a-row v-if="!matched">
                      <p style="color: red">* Password doesn't match</p>
                    </a-row>
                    <a-row v-if="!lengthNotMatch">
                      <p style="color: red">* Password must be 5 characters or more</p>
                    </a-row>
                  </a-modal>
                </sdCards>
              </VerticalFormStyleWrap>
            </BasicFormWrapper>
          </a-col>
        </a-row>
      </Main>
    </div>
    <div v-else>
      <Main class="footer">
        <div class="loaderstyle">
          <div class="loader"></div>
        </div>
      </Main>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import PaymentCard from './PaymentCard.vue';
import StripeField from './StripeFields.vue';
import { Main } from '../styled';
import { CardStyleWrapper } from './../uiElements/ui-elements-styled';
import { BasicFormWrapper } from './../styled';
import { VerticalFormStyleWrap } from '../forms/overview/Style';
import Cookies from 'js-cookie';
import { Notification } from 'ant-design-vue';

export default {
  name: 'Account',
  data() {
    return {
      firstName: '',
      cardBrand: '',
      lastName: '',
      center: { lat: 51.093048, lng: 6.84212 },
      marker: [
        {
          position: {
            lat: 51.093048,
            lng: 6.84212,
          },
        },
      ],
      address: '',
      phone: '',
      gender: '',
      email: '',
      hiddenCardInfo: '',
      hiddenCardDate: '',
      hiddenCardCvc: '',
      accountsSave: false,
      password: '',
      cardHolderName: '',
      cardInformation: '',
      shipping: '',
      loader: true,
      visible: false,
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      matched: true,
      lengthNotMatch: true,
      changeCreditCardDetails: false,
      billingAddress: {
        street: '',
        city: '',
        state: '',
        zip: '',
      },
      shipingAddress: {
        street: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
      },
      apiData: {
        expdate: '',
        mm: '',
        yy: '',
        cvc: '',
        Cardnumber: '',
        payment: '',
        zip: '',
        brand: '',
      },
    };
  },
  components: {
    Main,
    CardStyleWrapper,
    BasicFormWrapper,
    VerticalFormStyleWrap,
    PaymentCard,
    StripeField,
  },
  mounted() {
    this.getAccounts();
  },
  methods: {
    setPlace(val) {
      this.address = val.formatted_address;
      this.center = {
        lat: val.geometry.location.lat(),
        lng: val.geometry.location.lng(),
      };
    },
    async getAccounts() {
      await axios
        .get('get-account.php')
        // .get('https://127.0.0.1:8000/api/billing')
        // .get('https://api.getbetterdoctor.com/api/billing')
        .then((res) => {
          console.log('res');
          console.log(res);

          if (res.status === false) {
            this.loader = false;
          }

          let billing = res.data.billing;
          let customer = res.data.customer;
          let cardNumber = billing.payment.toString();
          this.hiddenCardInfo = '**** **** **** ' + cardNumber.substr(cardNumber.length - 4);
          this.hiddenCardDate = billing.mm + '/' + billing.yy;
          this.hiddenCardCvc = billing.cvc;
          this.firstName = customer.first_name;
          this.lastName = customer.last_name;
          this.phone = customer.phone;
          this.gender = customer.gender;
          this.email = customer.email;
          this.password = customer.password;
          this.billingAddress.street =  customer.address1.substring(0,35)+ ((customer.address1.length >34)? "..":'');
          this.billingAddress.city = customer.city.substring(0,35)+ ((customer.city.length >34)? "..":'');
          this.billingAddress.state = customer.state.substring(0,35)+ ((customer.state.length >34)? "..":'');
          this.billingAddress.zip = customer.zip;
          this.address = billing.address;
          this.shipingAddress.street = billing.street.substring(0,35)+ ((billing.street.length >34)? "..":'');
          this.shipingAddress.city = billing.city.substring(0,35)+ ((billing.city.length >34)? "..":'');
          this.shipingAddress.state = billing.state.substring(0,35)+ ((billing.state.length >34)? "..":'');
          this.shipingAddress.zip = billing.zip;
          this.cardBrand = billing.brand;
          let bcard = {
            number: null,
            cvc: null,
            expdate: null,
          };
          Cookies.set('bcard', bcard);
          // console.log(this.firstName);

          if (res.status === true) {
            this.loader = false;
          }
        })
        .catch((err) => {
          console.log('err');

          console.log(err);
        });
      this.loader = false;
    },
    openPasswordModal() {
      this.visible = true;
    },
    handleOk(e) {
      // console.log()
      console.log('Current ==>' + this.currentPassword);
      if (this.newPassword.length < 5 || this.confirmPassword < 5) {
        this.lengthNotMatch = false;
        this.confirmPassword = null;
        this.newPassword = null;
      } else if (this.newPassword !== this.confirmPassword) {
        console.log('not match');
        this.lengthNotMatch = true;

        this.matched = false;
        this.confirmPassword = null;
        this.newPassword = null;
      } else {
        this.lengthNotMatch = true;

        console.log('match');
        this.matched = true;

        setTimeout(() => {
          this.visible = false;
        }, 1000);
      }
      //this.updateInfo();
    },
    handleCancel(e) {
      console.log('Clicked cancel button');
      this.visible = false;
    },
    async updateInfo() {
      console.log('clicked');
      console.clear();
      // if(this.apiData.Cardnumber.length > 4){
      // console.log(this.apiData)

      // }
      // return
      // if (typeof Cookies.get('card') !== 'undefined') {
      //   let card = JSON.parse(Cookies.get('card'));
      //   // console.log(card)
      //   console.log( card.number !== null ? 'null' : 'calue')
      //   this.apiData.Cardnumber = card.number !== null ? card.number : null ;
      //   this.apiData.payment = card.number !== null ? card.number : null;
      //   this.apiData.cvc = card.cvc !== null ? card.cvc : null;
      //   this.apiData.expdate = card.expiration !== null ? card.expiration : null;
      //   this.apiData.mm = card.expiration !== null ? card.expiration.split('/')[0] : null ;
      //   this.apiData.yy = card.expiration !== null ? card.expiration.split('/')[1] : null;

      // }

      if (this.newPassword.length > 4 && this.newPassword != this.confirmPassword) {
        this.accountsSave = false;
      } else {
        this.accountsSave = true;

        await axios
          .post('update-billing.php', {
            // .post('https://127.0.0.1:8000/api/billing_update', {
            email: this.email,
            mm: this.apiData.mm,
            yy: this.apiData.yy,
            cvc: this.apiData.cvc,
            brand: this.apiData.brand,
            payment: this.apiData.payment,
            expdate: this.apiData.expdate,
            cardHolderName: this.cardHolderName,
            address: this.address,
            cpassword: this.currentPassword,
            confirmpassword: this.confirmPassword,
            first_name: this.firstName,
            last_name: this.lastName,
            gender: this.gender,
            phone: this.phone,
            billingAddress: this.billingAddress,
            shippingAddress: this.shipingAddress,
          })
          .then((res) => {
            let response = res.data;
            console.log(response);

            if (response.status === false) {
              this.accountsSave = false;
              Notification.open({
                message: 'Data update failed',
                description: response.message,
                icon: <sdFeatherIcons type="smile" size="14" style="background-color: #1BABA8" />,
              });

              console.log('updating failed');
            }

            if (response.status === true) {
              // console.log('Success');
              this.accountsSave = false;

              Notification.open({
                message: 'Update Successful',
                description: 'Successfully updated ',
                icon: <sdFeatherIcons type="smile" size="14" style="background-color: #1BABA8" />,
              });
              console.log('Successful');
            }
          })
          .catch((err) => {
            console.log('err');
            this.accountsSave = false;
            Notification.open({
              message: 'Data update failed',
              description: 'Error in processing',
              icon: <sdFeatherIcons type="smile" size="14" style="background-color: #1BABA8" />,
            });

            console.log('updating failed');
            console.log(err);
          });
      }
    },
    showCreditCard() {
      this.changeCreditCardDetails = true;
    },
    changePaymentDetail() {
      this.changeCreditCardDetails = false;
    },
  },
};
</script>

<style scoped>
.icon {
  padding: 10px;
  background: white;
  color: #0CA2AD;
  max-width: 50px;
  text-align: center;
  /* border: 1px solid; */
}
.locationicon {
  display: flex;
  align-items: center;
  padding: 7px;
  position: absolute;
  top: -9px;
  left: 10px;
}
.map[data-v-447553c1] {
  height: 47px;
  border-radius: 11px;
  width: 68%;
  text-align: left;
  padding-left: 40px;
  border: 1px solid #e3e6ef;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .cardnmb {
    width: 300px !important;
  }
}
</style>  