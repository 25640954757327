<template>
 <div class="input-container" style="border: 2px solid #0CA2AD;">
    <!-- <i class="fa fa-user icon"></i> -->
    <!-- <masked-input v-model="phone" mask="\+\1 (111) 1111-11" placeholder="Phone" /> -->
    <!-- <i class="fa fa-duotone fa-credit-card icon"></i> -->
    <img class="icon" v-if="brand == 'unknown'" src="https://raw.githubusercontent.com/aaronfagan/svg-credit-card-payment-icons/master/mono/generic.svg?sanitize=true" alt="Generic Card">
    <img class="icon" v-if="brand == 'visa'" src="https://raw.githubusercontent.com/aaronfagan/svg-credit-card-payment-icons/master/flat/visa.svg?sanitize=true" alt="Generic Card">
    <img class="icon" v-if="brand == 'mastercard'" src="https://raw.githubusercontent.com/aaronfagan/svg-credit-card-payment-icons/master/flat/mastercard.svg?sanitize=true" alt="Generic Card">
    <img class="icon" v-if="brand == 'jcb'" src="https://raw.githubusercontent.com/aaronfagan/svg-credit-card-payment-icons/master/flat/jcb.svg?sanitize=true" alt="Generic Card">
    <img class="icon" v-if="brand == 'amex'" src="https://raw.githubusercontent.com/aaronfagan/svg-credit-card-payment-icons/master/flat/amex.svg?sanitize=true" alt="Generic Card">
    <img class="icon" v-if="brand == 'discover'" src="https://raw.githubusercontent.com/aaronfagan/svg-credit-card-payment-icons/master/flat/discover.svg?sanitize=true" alt="Generic Card">
    <img class="icon" v-if="brand == 'dinersclub'" src="https://raw.githubusercontent.com/aaronfagan/svg-credit-card-payment-icons/master/flat/diners.svg?sanitize=true" alt="Generic Card">
    <!-- <font-awesome-icon icon="faSpinner" class="icon" /> -->
    <!-- <masked-input class="input-field" ref="number" id="number" type="text" placeholder="4111 1111 1111 1111"  v-model="result.card.number" @input="validateCnumber" mask="1111 1111 1111 1111"  />
    <masked-input class="input-field-exp" ref="expiry" id="expiry" type="text" placeholder="mm/yy"  v-model="result.card.expiration" @input="validateCexpiry" mask="11/11"  />
    <masked-input class="input-field-cvc" ref="cvv" id="cvv" type="text" placeholder="cvv" v-model="result.card.cvc" @input="validateCcvv" mask="1111"  />
    <masked-input class="input-field-zip" ref="zip" id="zip" type="text" placeholder="zip" v-model="result.card.postalCode" @input="validateCzip" mask="11111"  /> -->
    
            
            
    <!-- <input class="input-field" ref="number"  id="number" type="text" placeholder="4111 1111 1111 1111" pattern="\d*" v-model="number" @input="validateCnumber" v-on:keydown="formatCardNumber"  v-on:keypress="isLetter($event)" required    name="usrnm"> -->
    <input class="input-field" ref="number"  id="number" type="text" placeholder="4111 1111 1111 1111"  v-model="number" @input="validateCnumber" v-on:keydown="formatCardNumber"  v-on:keypress="isLetter($event)" required  maxlength="19"   name="usrnm">
    <input class="input-field-exp" ref="expiry" id="expiry" type="text" placeholder="mm/yy"   v-model="expiry" @input="validateCexpiry" v-on:keydown="formatCardExpiry"  v-on:keypress="isLetter($event)" required  maxlength="5" name="usrnm">
    <input class="input-field-cvc" ref="cvv" id="cvv" type="text" placeholder="cvv" v-model="cvv"  @input="validateCcvv" v-on:keypress="isLetter($event)" required  maxlength="4" name="usrnm">
    <input class="input-field-zip" ref="zip" id="zip" type="text" placeholder="zip" v-model="zip"  @input="validateCzip" v-on:keypress="isLetter($event)" required  maxlength="5" name="usrnm">
    <!-- <input class="input-field-exp" ref="expiry" type="text" placeholder="mm / yy" name="usrnm"> -->
    <!-- <input class="input-field-zip" ref="zip" type="text" placeholder="zip" name="usrnm"> -->
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
// import MaskedInput from 'vue-masked-input'
import { mapState } from "vuex";
import VueCookies from 'js-cookie';

export default {
  name: "stripe-fields",
  props:['apiData'],
   
  data() {
    return {
      number: null,
      phone: null,
      expiry: null,
      cvv: null,
      zip: null,
      brand: 'unknown',
      // result: null,
    }
  },
  components: {
    FontAwesomeIcon,
    // MaskedInput
  },
   computed: {
    ...mapState({
      result: state => state.result
    })
  },
  methods:{ 
     formatCardNumber(){
      console.log('formatCardNumber')
      let number = this.number.replace(/\D/g, "")
      this.number = number ? number.match(/.{1,4}/g).join(' ') : '' 
      // console.log('this.number')
      // console.log(this.number)

      // VueCookies.set('number',)
      // return  this.number ? this.number.match(/.{1,4}/g).join(' ') : '';
    } ,
    formatCardExpiry(){
      console.log('formatCardNumber')
      let expiry = this.expiry.replace(/\D/g, "")
      this.expiry = expiry ? expiry.match(/.{1,2}/g).join('/') : ''; 
      // return  this.number ? this.number.match(/.{1,4}/g).join(' ') : '';
    } ,
     isLetter(e) {
  let char = String.fromCharCode(e.keyCode); // Get the character
  if(/^[0-9]+$/.test(char)) return true; // Match with regex 
  else e.preventDefault(); // If not match, don't add to input text
},
    backspaceExpiry(){
      console.log('backspaceExpiry')
    },
    validateCnumber() {
      let value = this.number
      this.apiData.Cardnumber = value
      this.apiData.payment = value
      console.log('this.result.card.number')
      console.log(this.apiData.Cardnumber)
      console.log(value)
        let visa = value
          .replace(/\D/g, "")
          .match(/^4[0-9]{12}(?:[0-9]{3})?$/);
          
        let master = value
        .replace(/\D/g, "")
        .match(/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/);
        
          let jcb = value
        .replace(/\D/g, "")
        .match(/^(?:2131|1800|35\d{3})\d{11}$/);
        
          let amex = value
        .replace(/\D/g, "")
        .match(/^3[47][0-9]{13}$/);

        let discover = value
        .replace(/\D/g, "")
        .match(/^65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})$/);
        
        let dinersclub = value
        .replace(/\D/g, "")
        .match(/^3(?:0[0-5]|[68][0-9])[0-9]{11}$/);
        
          if(visa){
            console.log('visa')
            // console.log(visa.input.length)

            if(visa.input.length > 15){
            
            window.setTimeout(() => document.getElementById('expiry').focus(), 0);
            // alert(document.getElementById('expiry'))
            this.brand = 'visa'
            this.apiData.brand = 'visa'
    //   this.result.card.brand = 'visa'

            return false
            }
            // this.brand = 'visa'
          }
          
          if(master){
             window.setTimeout(() => document.getElementById('expiry').focus(), 0);
            this.brand = 'mastercard'
            this.apiData.brand = 'mastercard'

    //   this.result.card.brand = 'mastercard'

            return false
          }

          
          if(jcb){
            window.setTimeout(() => document.getElementById('expiry').focus(), 0);


            this.brand = 'jcb'
            this.apiData.brand = 'jcb'
            
    //   this.result.card.brand = 'jcb'

            return false

          }

           
          if(amex){
            window.setTimeout(() => document.getElementById('expiry').focus(), 0);


            this.brand = 'amex'
            this.apiData.brand = 'amex'

    //   this.result.card.brand = 'amex'

            return false

          }

          if(discover){
            window.setTimeout(() => document.getElementById('expiry').focus(), 0);


            this.brand = 'discover'
            this.apiData.brand = 'discover'

    //   this.result.card.brand = 'discover'

            return false

          }

          if(dinersclub){
            window.setTimeout(() => document.getElementById('expiry').focus(), 0);


            this.brand = 'dinersclub'
            this.apiData.brand = 'dinersclub'

    //   this.result.card.brand = 'dinersclub'

            return false

          }

          this.brand = 'unknown'
            this.apiData.brand = 'unknown'

    },
    validateCexpiry(event){
      
      let value = this.expiry

      this.apiData.expdate = value
      console.log('this.result.card.expiration')
      console.log( this.apiData.expdate)

      console.log( value.replace(/[^0-9\.]+/g, "").length)
      var input = document.getElementById('expiry');
    console.log('input')
    console.log(input.value.replace(/[^0-9\.]+/g, "").length)
    
      input.addEventListener('keydown', function(event) {
      const key = event.key;
      // console.log('key')
      // console.log(key)
      if (key === "Backspace" && input.value.replace(/[^0-9\.]+/g, "").length == 0) {
        document.getElementById('number').focus()
        console.log('backspace')
        return
      }
      });

      let month = value.split('/')[0]
      let year = value.split('/')[1]
      this.apiData.mm = month
      this.apiData.yy = year
      let currentYear = new Date().getYear()
      let currentMonth = new Date().getMonth()

      let  today = new Date();
      let someday = new Date();
      someday.setFullYear('20'+year, month, 1);

      if (someday > today) { //valid
        console.log('someday > today')
        console.log(value)
        // document.getElementById('cvv').focus()
             window.setTimeout(() => document.getElementById('cvv').focus(), 0);

      return false
      }else{
        console.log('someday < today')

        console.log(value)
      }

      if(month < currentMonth) {console.log('month not valid')}
    
    },
    validateCcvv(event){
      var self = this;
      let value = this.cvv.replace(/[^0-9\.]+/g, "")
      // if(value.includes('_')){
      this.apiData.cvc = value
      console.log('this.result.card.cvc')
      console.log( this.apiData.cvc)
      // }
         console.log( value.replace(/[^0-9\.]+/g, "").length)
      var input = document.getElementById('cvv');
      var newExpiry = this.expiry
      console.log('input')
      console.log(input.value.replace(/[^0-9\.]+/g, "").length)
      input.addEventListener('keydown', function(event) {
      const key = event.key;
      // console.log('key')
      // console.log(key)
      if (key === "Backspace" && input.value.replace(/[^0-9\.]+/g, "").length == 0) {
        // document.getElementById('expiry').focus()
        // this.expiry = this.expiry.slice(0,-1)
        console.log('this.expiry')
        // console.log(self.expiry.slice(0,-1))
        var  tExp = self.expiry
        // console.log('self.expiry.length')
        // console.log(self.expiry.length)
        if(self.expiry.length === 5){
        self.expiry = tExp.substring(0, tExp.length - 1)
        }
        // console.log(self.expiry)
        // console.log(document.getElementById('expiry').value.slice(0,-1))
        window.setTimeout(() => document.getElementById('expiry').focus(), 0);

        console.log('backspace')
        return
      }
      });
      console.log('value')
      console.log(value)
      console.log(value.length)
      if(this.brand == 'amex' && value.length == 4){
        // document.getElementById('zip').focus()
      window.setTimeout(() => document.getElementById('zip').focus(), 0);

      }

      if(this.brand !== 'amex' && value.length == 3){
        // document.getElementById('zip').focus()
        window.setTimeout(() => document.getElementById('zip').focus(), 0);


      }
    },
    validateCzip(){
      var self = this
      let value = this.zip.replace(/[^0-9\.]+/g, "")

      this.apiData.zip = value
      console.log('this.result.card.postalCode')
      console.log(this.apiData.zip)
      
      console.log( value.replace(/[^0-9\.]+/g, "").length)
      var input = document.getElementById('zip');
      console.log('input')
      console.log(input.value.replace(/[^0-9\.]+/g, "").length)
      input.addEventListener('keydown', function(event) {
      const key = event.key;
      // console.log('key')
      // console.log(key)
      var card = { number: this.number, expiry: this.expiry, cvv: this.cvv, zip: this.zip, brand: this.brand}
      console.log(this.number)
      VueCookies.set('card', JSON.stringify(card))
      if (key === "Backspace" && input.value.replace(/[^0-9\.]+/g, "").length == 0) {
        // document.getElementById('cvv').focus()
        var  tExp = self.cvv
        // console.log('self.expiry.length')
        // console.log(self.expiry.length)
        if(self.cvv.length >=3){
        self.cvv = tExp.substring(0, tExp.length - 1)
        }
        window.setTimeout(() => document.getElementById('cvv').focus(), 0);

        console.log('backspace')
        return
      }
      });
    }
  },
 
};
</script>
<style scoped>
.input-container {
  display: flex;
  width: 100%;
  margin-bottom: 15px;
}

/* Style the form icons */
.icon {
  padding: 10px;
  background: white;
  color: #0CA2AD;
  max-width: 50px;
  text-align: center;
  /* border: 1px solid; */
}

/* Style the input fields */
.input-field {
  width: 100%;
  padding: 10px;
  outline: none;
  /* border: 1px solid #0CA2AD; */
  border: none;

}

.input-field-exp {
  width: 30%;
  padding: 10px;
  /* border: 1px solid #0CA2AD; */
  border-left: none;
  border: none;
  outline: none;

}

.input-field-cvc {
  width: 20%;
  padding: 10px;
  /* border: 1px solid #0CA2AD; */
  /* border: none; */
  border: none;
  outline: none;
  overflow: none;

}
.input-field-zip {
  width: 20%;
  padding: 10px;
  /* border: 1px solid #0CA2AD; */
  border: none;
  outline: none;

}

.input-field:focus {
  outline: none;
}


</style>