<template>
  <div>
    <div v-if="!loader">
      <Main class="footer">
        <a-row :gutter="25">
          <a-col :lg="4" :xl="7" :md="10" :xs="24">
            <h3 style="padding-top:40px; padding-left:20px; color:#6D6868; font-weight: bold;">Labs</h3>
          </a-col>
        </a-row>

        <a-row :gutter="25">
          <a-col :lg="12" :xl="8" :md="10" :xs="20">
            <!-- <h3 style="padding-top:40px; padding-left:20px; color:#6D6868; font-weight: bold;">Treatments</h3> -->
            <!-- <sdCards title="Simple card" size="default"> -->
            <a-col style="padding-top: 30px">
              <div class="bbc">
                <h3 class="cms">COMING SOON</h3>
              </div>
              <!-- <CardStyleWrapper >
                <sdCards :headless="true" :border="true"  :style="{ width: 400 }" >
                 <a-row> -->
              <!-- </a-row>
                </sdCards>
              </CardStyleWrapper> -->
            </a-col>

            <!-- </sdCards> -->
          </a-col>
        </a-row>
      </Main>
    </div>
    <div v-else>
      <Main class="footer">
        <div class="loaderstyle">
          <div class="loader"></div>
        </div>
      </Main>
    </div>
  </div>
</template>

<script>
import { Main } from '../styled';
import { defineAsyncComponent } from 'vue';
import { CardStyleWrapper } from './../uiElements/ui-elements-styled';
import { VerticalFormStyleWrap } from '../forms/overview/Style';
import { BasicFormWrapper } from './../styled';
const SocialMediaOverview = defineAsyncComponent(() => import('./overview/index/SocialMediaOverview'));
const FacebookOverview = defineAsyncComponent(() => import('./overview/index/FacebookOverview'));
const YoutubeSubscribers = defineAsyncComponent(() => import('./overview/index/YoutubeSubscribers'));
const TwitterOverview = defineAsyncComponent(() => import('./overview/index/TwitterOverview'));
const InstagramOverview = defineAsyncComponent(() => import('./overview/index/InstagramOverview'));
const LinkedinKeyMetrics = defineAsyncComponent(() => import('./overview/index/LinkedinKeyMetrics'));
const SocialTrafficMetrics = defineAsyncComponent(() => import('./overview/index/SocialTrafficMetrics'));

export default {
  name: 'Lab',
  components: {
    Main,
    CardStyleWrapper,
    SocialMediaOverview,
    FacebookOverview,
    YoutubeSubscribers,
    TwitterOverview,
    InstagramOverview,
    LinkedinKeyMetrics,
    SocialTrafficMetrics,
    BasicFormWrapper,
    VerticalFormStyleWrap,
  },
  data() {
    return {
      // loader: true
    };
  },
  async setup() {},
};
</script>
<style scoped>
.bbc {
  background-image: url('../../static/img/background.png');
  /* background-image: url('https://pngimage.net/wp-content/uploads/2018/06/png-background-images-1-1.png'); */
  background-size: cover;
  min-height: 258px;
  /* min-width: 605px; */
  /* height: 100vh; */
  /* padding-left: 10% ; */
  background-color: white;
  /* display: block; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.cms {
  color: #707070;
  font-size: 20px;
  font-weight: bolder;
  /* padding-left: 80px; */
  /* padding-top: 40px; */
  /* padding-bottom: 40px; */
}
.footer {
  min-height: calc(100vh - 114px);
}
.spinclass {
  margin-top: 300px;
  margin-left: 800px;
}
.ant-spin-dot-item {
  background-color: rgb(13, 179, 181) !important;
}
.loader {
  border: 10px solid #0db0b2;
  border-radius: 50%;
  border-top: 4px solid white;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}
.loaderstyle {
  align-content: center;
  padding-top: 20%;
  padding-left: 40%;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
