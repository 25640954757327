<template>
  <div>
    <div v-if="load">
      <Main class="footer">
        <a-row :gutter="25">
          <a-col :lg="4" :xl="7" :md="10" :xs="24">
            <h3 style="padding-top: 40px; padding-left: 20px; color: #6d6868; font-weight: bold">Treatments</h3>
          </a-col>
        </a-row>

        <a-row class="center">
          <a-col
            v-for="treatment in treatments"
            :key="treatment.id"
            :lg="8"
            :xl="8"
            :md="12"
            :xs="24"
            class="cardHolder"
          >
            <CardStyleWrapper>
              <sdCards :headless="true" :border="true">
                <a-row>
                  <a-col :lg="24" :xl="24" :md="24" :xs="24">
                    <img
                      :src="  treatment.image"
                      class="mainCardImg"
                      alt=""
                      srcset=""
                    />
                  </a-col>
                </a-row>
                <a-row>
                  <a-col :lg="24" :xl="24" :md="24" :xs="24" style="padding-top: 10px"> {{ treatment.name }} </a-col>
                </a-row>
                <a-row style=" padding-top:10px; display:flex;justify-content:center">
                  <sdButton
                    size="small"
                    type="primary"
                    class="button"
                    style="background-color: #0db3b5;"
                    @click="gotoUrl(treatment.link)"
                  >
                    Start Online Visit
                  </sdButton>
                </a-row>
              </sdCards>
            </CardStyleWrapper>
          </a-col>
        </a-row>

        <!-- <splide :treatments="treatments" :options="options">
          <splide-slide v-for="treatment in treatments" :key="treatment.id" class="center">
            <CardStyleWrapper>
              <sdCards :headless="true" :border="true" >
                <a-row>
                  <a-col :lg="16" :xl="24" :md="10" :xs="24">
                    <img :src="treatment.image_url" class="mainCardImg" alt="" srcset="" />
                  </a-col>
                </a-row>
                <a-row>
                  <a-col :lg="16" :xl="24" :md="10" :xs="24" style="padding-top: 10px"> {{ treatment.name }} </a-col>
                </a-row>
              </sdCards>
            </CardStyleWrapper>
          </splide-slide>
        </splide>
 -->
      </Main>
    </div>

    <div v-else>
      <Main class="footer">
        <a-spin size="large" class="spinclass" />
      </Main>
    </div>
  </div>
</template>

<script>
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import { Main } from '../styled';
import { defineAsyncComponent } from 'vue';
import { CardStyleWrapper } from './../uiElements/ui-elements-styled';
import axios from 'axios';
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import Cookies from 'js-cookie';
import { reactive } from 'vue';

const SocialMediaOverview = defineAsyncComponent(() => import('./overview/index/SocialMediaOverview'));
const FacebookOverview = defineAsyncComponent(() => import('./overview/index/FacebookOverview'));
const YoutubeSubscribers = defineAsyncComponent(() => import('./overview/index/YoutubeSubscribers'));
const TwitterOverview = defineAsyncComponent(() => import('./overview/index/TwitterOverview'));
const InstagramOverview = defineAsyncComponent(() => import('./overview/index/InstagramOverview'));
const LinkedinKeyMetrics = defineAsyncComponent(() => import('./overview/index/LinkedinKeyMetrics'));
const SocialTrafficMetrics = defineAsyncComponent(() => import('./overview/index/SocialTrafficMetrics'));

export default {
  name: 'AllTreatment',
  components: {
    Main,
    CardStyleWrapper,
    SocialMediaOverview,
    FacebookOverview,
    YoutubeSubscribers,
    TwitterOverview,
    InstagramOverview,
    LinkedinKeyMetrics,
    SocialTrafficMetrics,
    Splide,
    SplideSlide,
  },
  data() {
    return {
      flag: 'english',
      uId: null,
      treatments: null,
      medicines: null,
      load: false,
      options: {
        rewind: true,
        width: '100%',
        perPage: 6,
        gap: '1rem',
        autoplay: true,
        pauseOnHover: false,
        arrows: 'slider',
      },
    };
  },
  methods: {
    gotoUrl(link) {
      window.open(link, '_blank');
    },
    async getTreatmentsMeds() {
      axios
        .get('all-treatment.php')
        // .get('https://api.getbetterdoctor.com/api/medicines/filtered')
        .then(response => {
          const res = response.data;

          if (res.status === true) {
            this.treatments = res.treatments;
            this.medicines = res.medicines;

            console.log(res.treatments);
          }
        })
        .catch(error => {
          console.log(error);
        });
      this.load = true;
    },
  },
  mounted() {
    this.getTreatmentsMeds();
  },
};
</script>
<style scoped>
.cardHolder {
  padding: 30px;
}
.mainCardImg {
  height: 100px;
  width: 70px;
}
.center {
  margin: auto;
  text-align: center;
  /* width: 50%; */
  /* border: 3px solid green; */
  padding: 10px;
}
.footer {
  min-height: calc(100vh - 114px);
}
/* @media (min-width: 300px) and (max-width: 768px) {
  .ant-card-bordered {
    border: 1px solid #f0f0f0;
   
  }
} */
/* @media (min-width: 200px) and (max-width: 299px) {
  .ant-card-bordered {
    border: 1px solid #f0f0f0;
    height: 300px;
  }
  .button {
    margin-left: 0px !important;
    margin-top: 10px;
  }
} */
/* @media (min-width: 768px) and (max-width: 1023px) {
  .button {
    margin-left: 0px !important;
    margin-top: 10px;
  }
} */
/* @media (min-width: 1024px) and (max-width: 1200px) {
  .button {
    margin-left: -80px !important;
    margin-top: 50px;
  }
} */
.spinclass {
  margin-top: 300px;
  margin-left: 800px;
}
</style>
<style>
.ant-spin-dot-item {
  background-color: rgb(13, 179, 181) !important;
}
</style>
