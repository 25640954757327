<template>
  <div>
    <div v-if="!loader">
      <Main class="footer">
        <a-row :gutter="25">
          <a-col :lg="4" :xl="7" :md="10" :xs="24">
            <h3
              style="
                padding-top: 40px;
                padding-left: 20px;
                color: #6d6868;
                font-weight: bold;
              "
            >
              Treatments
            </h3>
          </a-col>
        </a-row>
        <a-row :gutter="25">
          <!-- col for main card -->
          <a-col
            :lg="8"
            :xl="7"
            :md="12"
            :xs="24"
            v-for="order in orders"
            :key="order.id"
          >
            <a-col style="padding-top: 30px">
              <CardStyleWrapper>
                <sdCards :headless="true" :border="true" :style="{ width: 440 }">
                  <a-row>
                    <!-- col for all things in card -->
                    <a-col :lg="18" :xl="24" :md="24" :xs="24">
                      <a-row v-if="order.medicine">
                        <p style="color: #6d6868; font-weight: bold">
                          {{ order.medicine.name }}, {{ order.medicine.rx_dosage }}
                        </p>
                      </a-row>
                      <a-row v-else>
                        <p style="color: #6d6868; font-weight: bold">Lab Test</p>
                      </a-row>
                      <a-row>
                        <p style="color: #0db3b5; font-weight: bold">
                          {{ order.treatment.name }}
                        </p>
                      </a-row>

                      <!-- <a-row v-if="order.treatment.name == 'Covid-19'">
                        <p style="color: #6d6868">
                          Shipping:
                          <span style="color: #0db3b5; font-weight: bold">{{ future }}</span>
                        </p>
                      </a-row> -->
                      <a-row>
                        <p style="color: #6d6868">
                          Shipping:
                          <span style="color: #0db3b5; font-weight: bold">{{
                            dateFormat(String(order.next_shipment)).format("MM-DD-YYYY")
                          }}</span>
                        </p>
                        <span
                          v-if="order.casestatus === 'Shipped'"
                          style="padding-left: 5px"
                          @click="showModal(order)"
                        >
                          <!-- <span   style="padding-left:5px" @click="showModal(order)"> -->
                          <sdFeatherIcons
                            color="#C5C5C5"
                            title="Change shipment date"
                            type="edit"
                          />
                        </span>
                      </a-row>
                      <a-row>
                        <p style="color: #6d6868">
                          Status:
                          <span style="color: #0db3b5; font-weight: bold">{{
                            order.casestatus
                          }}</span>
                        </p>
                      </a-row>

                      <a-row>
                        <p style="color: #6d6868">
                          Frequency:

                          <span
                            v-if="order.frequency === '90'"
                            style="color: #0db3b5; font-weight: bold"
                            >Quarterly 
                          </span>
                          <span
                            v-if="order.frequency === '30'"
                            style="color: #0db3b5; font-weight: bold"
                            >Monthly
                          </span>
                          <span
                            v-if="order.frequency === '1'"
                            style="color: #0db3b5; font-weight: bold"
                            >Monthly
                          </span>
                        </p>
                      </a-row>

                      <a-row class="laprow">
                        <!-- col for alll buttons -->
                        <a-col :lg="24" :xl="24" :md="24" :xs="24">
                          <!-- v-if="order.casestatus !== 'Pending Doctor Review'" -->
                          <!-- <a-row> -->
                          <!-- <a-col :lg="24" :xl="24" :md="24" :xs="7"> -->
                          <sdButton
                            size="small"
                            type="primary"
                            class="buttondelay"
                            v-if="
                              order.casestatus !== 'Cancelled' &&
                              order.casestatus !== 'Cancelled-Refunded'
                            "
                            style="
                              background-color: #0db3b5;
                              margin-left: 1%;
                              margin-top: 2%;
                            "
                            @click="showDelayPrompt(order)"
                          >
                            Delay
                          </sdButton>
                          <!-- </a-col> -->

                          <!-- v-if="order.casestatus !== 'Pending Doctor Review'" -->

                          <!-- <a-col :lg="24" :xl="24" :md="24" :xs="8"> -->
                          <!-- <sdButton
                            v-if="order.casestatus !== 'Pending Doctor Review'"
                            size="small"
                            type="primary"
                            class="buttonsooner"
                            @click="showModal2(order)"
                            style="background-color: #0db3b5; margin-left: 1%; margin-top:2%"
                          >
                            Get Sooner
                          </sdButton> -->
                          <!-- </a-col> -->
                          <!-- <a-col :lg="24" :xl="24" :md="24" :xs="6"> -->
                          <sdButton
                            size="small"
                            type="primary"
                            class="button"
                            @click="goToUrl(order)"
                            style="
                              background-color: #0db3b5;
                              margin-left: 1%;
                              margin-top: 2%;
                            "
                            >Chat

                            <!-- margin-left: 10%; -->
                          </sdButton>

                          <sdButton
                            size="small"
                            type="primary"
                            class="buttondelay"
                            style="
                              background-color: #0db3b5;
                              margin-left: 1%;
                              margin-top: 2%;
                            "
                            @click="openModalReorder(order)"
                          >
                            Refill
                          </sdButton>
                          <sdButton
                            size="small"
                            type="primary"
                            class="buttondelay"
                            style="
                              background-color: #0db3b5;
                              margin-left: 1%;
                              margin-top: 2%;
                            "
                            @click="openModalCancel(order)"
                            v-if="
                              order.casestatus !== 'Cancelled' &&
                              order.casestatus !== 'Cancelled-Refunded'
                            "
                          >
                            Cancel
                          </sdButton>
                          <sdButton
                            size="small"
                            type="warning"
                            class="button"
                            v-if="!order.document_submitted"
                            @click="openModalVouched(order)"
                            style="margin-left: 1%; margin-top: 2%"
                            >Verify

                            <!-- margin-left: 10%; -->
                          </sdButton>
                          <!-- </a-col> -->
                          <!-- </a-row> -->
                          <!-- <sdButton size="small" type="primary" class="button" @click="goToUrl()" style="background-color:#0DB3B5; margin-top: 10px">Chat</sdButton> -->
                        </a-col>
                      </a-row>
                      <a-row class="laprow" v-if="!order.document_submitted">
                        <!-- col for alll buttons -->
                        <a-col :lg="24" :xl="24" :md="24" :xs="24">
                          <span class="warning">
                            * Your faceshot and photo id is not verified
                          </span>
                        </a-col>
                      </a-row>
                      <!-- <a-row class="btn2"> -->
                      <a-row>
                        <!-- <sdButton size="small" type="primary" class="button" style="background-color:#0DB3B5; margin-top: 10px"><router-link to="/app/chat/group">Chat Now</router-link></sdButton> -->
                      </a-row>
                    </a-col>
                    <!-- <img src="../../static/img/t_pill.png" class="bp" srcset="" />
                    <img src="../../static/img/med_pic.png" class="med" alt="medicine" /> -->
                  </a-row>
                </sdCards>
              </CardStyleWrapper>
            </a-col>
          </a-col>
        </a-row>
        <a-modal
          :visible="visibleOrder"
          :confirm-loading="confirmLoadingOrder"
          @ok="handleOkOrder"
          @cancel="handleOrderOrder"
        >
          <h2 class="text-center">Refill</h2>
          <a-row>
            <a-col :xl="24"
              ><h3 class="">
                You are about to place a refill request for
                {{ orderUpdateOrder.treatment.name }}
                {{
                  orderUpdateOrder.medicine
                    ? "," +
                      orderUpdateOrder.medicine.name +
                      " and " +
                      orderUpdateOrder.medicine.rx_dosage
                    : ""
                }}
                , ${{
                  parseInt(
                    !!orderUpdateOrder.total_amount ? orderUpdateOrder.total_amount : "0"
                  ) +
                  parseInt(
                    !!orderUpdateOrder.refund_amount
                      ? orderUpdateOrder.refund_amount
                      : "0"
                  ) +
                  parseInt(
                    !!orderUpdateOrder.coupon_amount
                      ? "0" + orderUpdateOrder.coupon_amount
                      : "0"
                  )
                }}
                Will be billed to your card on file. Are you sure you wish to proceed?
              </h3>
            </a-col>
          </a-row>
        </a-modal>
        <a-modal
          :visible="visiblePrompt"
          :footer="null"
          :confirm-loading="confirmLoadingPrompt"
          @cancel="handleCancelPrompt"
        >
          <h2 class="text-center">Delay Prompt</h2>
          <a-row>
            <a-col :xl="24"
              ><h3 class="">
                You are about to delay your refill of
                {{ orderUpdatePrompt.treatment.name }}
                {{
                  orderUpdatePrompt.medicine
                    ? "," +
                      orderUpdatePrompt.medicine.name +
                      " and " +
                      orderUpdatePrompt.medicine.rx_dosage
                    : ""
                }}
                , Please select a date below and Click Confirm if you wish to proceed.
              </h3>
            </a-col>
          </a-row>
          <a-row>
            <a-col :xl="12"
              ><h3 class="modal-header-inner">Select New Shipment Date:</h3>
            </a-col>
            <a-col :xl="12"
              ><a-date-picker @change="onChangeDate" :disabledDate="disabledDate"
            /></a-col>
          </a-row>
          <div class="ant-modal-footer">
            <button type="button" class="ant-btn" @click="handleCancelPrompt">
              Close
            </button>
            <button
              type="button"
              v-if="!confirmLoadingPrompt"
              class="ant-btn ant-btn-primary"
              @click="handleOkPrompt"
            >
              Confirm
            </button>
            <button type="button" v-if="confirmLoadingPrompt" class="spinner" disabled>
              <a-spin>
                <a-icon slot="indicator" type="loading" style="font-size: 35px" spin />
                <a-spin :indicator="indicator" />
              </a-spin>
            </button>
          </div>
        </a-modal>
        <a-modal
          :visible="visibleCancel"
          :footer="null"
          :confirm-loading="confirmLoadingCancel"
          @ok="handleOkCancel"
          @cancel="handleCancelOrder"
        >
          <h2 class="modal-header">Cancel Prompt</h2>
          <a-row>
            <a-col :xl="24"
              ><h3 class="modal-header-inner">
                You are about to cancel your prescription for
                {{ orderUpdateCancel.treatment.name }}
                {{
                  orderUpdateCancel.medicine
                    ? "," +
                      orderUpdateCancel.medicine.name +
                      " and " +
                      orderUpdateCancel.medicine.rx_dosage
                    : ""
                }}
                , Please note once you cancel you will have to re-undergo a doctor visit
                to get a new prescription, Alternatively you can delay your shipment.
              </h3>
            </a-col>
          </a-row>
          <div class="ant-modal-footer">
            <button type="button" class="ant-btn" @click="handleCancelOrder">
              Cancel
            </button>
            <button
              type="button"
              class="ant-btn ant-btn-primary"
              @click="handleCancelDelay"
            >
              Delay
            </button>
            <button
              type="button"
              v-if="!confirmLoadingCancel"
              class="ant-btn ant-btn-primary"
              @click="handleOkCancel"
            >
              Okay
            </button>
            <button type="button" v-if="confirmLoadingCancel" class="spinner" disabled>
              <a-spin>
                <a-icon slot="indicator" type="loading" style="font-size: 35px" spin />
                <a-spin :indicator="indicator" />
              </a-spin>
            </button>
          </div>
        </a-modal>
        <a-modal
          :visible="visibleCancelDelay"
          :footer="null"
          :confirm-loading="confirmLoadingPromptDelay"
          @cancel="handleCancelOrderDelay"
        >
          <h2 class="modal-header">Delay Order</h2>
          <a-row>
            <a-col :xl="24"
              ><h3 class="modal-header-inner">
                You are about to delay your refill of
                {{ orderUpdateCancel.treatment.name }}
                {{
                  orderUpdateCancel.medicine
                    ? "," +
                      orderUpdateCancel.medicine.name +
                      " and " +
                      orderUpdateCancel.medicine.rx_dosage
                    : ""
                }}
                , Please select a date below Click Continue if you wish to proceed.
              </h3>
            </a-col>
          </a-row>
          <a-row>
            <a-col :xl="12"
              ><h3 class="modal-header-inner">Select New Shipment Date:</h3>
            </a-col>
            <a-col :xl="12"
              ><a-date-picker @change="onChangeCancelDelay" :disabledDate="disabledDate"
            /></a-col>
          </a-row>
          <div class="ant-modal-footer">
            <button type="button" class="ant-btn" @click="handleCancelOrderDelay">
              Close
            </button>
            <button
              type="button"
              class="ant-btn ant-btn-primary"
              @click="handleCancelFromDelay"
            >
              Cancel
            </button>
            <button
              type="button"
              v-if="!confirmLoadingPromptDelay"
              class="ant-btn ant-btn-primary"
              @click="handleOkOrderDelay"
            >
              Continue
            </button>
            <button
              type="button"
              v-if="confirmLoadingPromptDelay"
              class="spinner"
              disabled
            >
              <a-spin>
                <a-icon slot="indicator" type="loading" style="font-size: 35px" spin />
                <a-spin :indicator="indicator" />
              </a-spin>
            </button>
          </div>
        </a-modal>
        <a-modal
          :visible="visibleCancelFromDelay"
          :footer="null"
          :confirm-loading="confirmLoadingPromptDelay"
          @cancel="handleCancelOrderFromDelay"
        >
          <h2 class="modal-header">Cancel Order</h2>
          <a-row>
            <a-col :xl="24"
              ><h3 class="modal-header-inner">
                We hate to see you leave and would love to keep you! If you’d like to keep
                your treatment and it’s a matter of price or you’d like to delay your next
                shipment. <br />
                Please give us a call at +1 (855) 785-1636 or email us at:
                support@getbetterdoctor.com.
              </h3>
            </a-col>
          </a-row>

          <div class="ant-modal-footer">
            <button type="button" class="ant-btn" @click="handleCancelOrderFromDelay">
              Close
            </button>
            <button
              type="button"
              class="ant-btn ant-btn-primary"
              @click="handleDelayFromDelay"
            >
              Delay
            </button>
            <button
              type="button"
              v-if="!confirmLoadingCancel"
              class="ant-btn ant-btn-primary"
              @click="handleOkCancelActual"
            >
              Continue
            </button>
            <button type="button" v-if="confirmLoadingCancel" class="spinner" disabled>
              <a-spin>
                <a-icon slot="indicator" type="loading" style="font-size: 35px" spin />
                <a-spin :indicator="indicator" />
              </a-spin>
            </button>
          </div>
        </a-modal>
        <a-modal
          :visible="visibleVouched"
          :confirm-loading="confirmLoadingVouched"
          @ok="handleOkVouched"
          @cancel="handleCancelVouched"
        >
          <h2 class="modal-header">Upload Documents</h2>
          <a-row>
            <a-col :xl="24"><h3 class="modal-header-inner">Upload Faceshot</h3> </a-col>
            <a-col :xl="12"
              ><input
                class="form-control"
                type="file"
                accept="image/*"
                v-on:change="onUploadFaceshot"
            /></a-col>
          </a-row>
          <a-row>
            <a-col :xl="24"><h3 class="modal-header-inner">Upload Photo Id</h3> </a-col>
            <a-col :xl="12"
              ><input
                class="form-control"
                type="file"
                accept="image/*"
                v-on:change="onUploadPhotoId"
            /></a-col>
          </a-row>
        </a-modal>
        <a-modal
          :visible="visible"
          :confirm-loading="confirmLoading"
          @ok="handleOk"
          @cancel="handleCancel"
        >
          <h2 class="modal-header">Change Shipment</h2>
          <a-row>
            <a-col :xl="12"
              ><h3 class="modal-header-inner">Select New Shipment Date:</h3>
            </a-col>
            <a-col :xl="12"
              ><a-date-picker @change="onChange" :disabledDate="disabledDate"
            /></a-col>
          </a-row>
        </a-modal>
        <a-modal
          :visible="visible2"
          :confirm-loading="confirmLoading2"
          @ok="handleOk2"
          @cancel="handleCancel2"
        >
          <h2 class="modal-header">Get Soon Shipment</h2>
          <a-row>
            <a-col :xl="12"
              ><h3 class="modal-header-inner">Select New Shipment Date:</h3>
            </a-col>
            <a-col :xl="12"
              ><a-date-picker @change="onChange2" :disabledDate="disabledDate2"
            /></a-col>
          </a-row>
        </a-modal>
      </Main>
    </div>
    <div v-else>
      <Main class="footer">
        <div class="loaderstyle">
          <div class="loader"></div>
        </div>
      </Main>
    </div>
  </div>
</template>

<script>
import { Main } from "../styled";
import { defineAsyncComponent } from "vue";
import { CardStyleWrapper } from "./../uiElements/ui-elements-styled";
import axios from "axios";
import Cookies from "js-cookie";
import { reactive } from "vue";
import { Notification } from "ant-design-vue";
import moment from "moment";

const SocialMediaOverview = defineAsyncComponent(() =>
  import("./overview/index/SocialMediaOverview")
);
const FacebookOverview = defineAsyncComponent(() =>
  import("./overview/index/FacebookOverview")
);
const YoutubeSubscribers = defineAsyncComponent(() =>
  import("./overview/index/YoutubeSubscribers")
);
const TwitterOverview = defineAsyncComponent(() =>
  import("./overview/index/TwitterOverview")
);
const InstagramOverview = defineAsyncComponent(() =>
  import("./overview/index/InstagramOverview")
);
const LinkedinKeyMetrics = defineAsyncComponent(() =>
  import("./overview/index/LinkedinKeyMetrics")
);
const SocialTrafficMetrics = defineAsyncComponent(() =>
  import("./overview/index/SocialTrafficMetrics")
);

export default {
  name: "Treatment",
  components: {
    Main,
    CardStyleWrapper,
    SocialMediaOverview,
    FacebookOverview,
    YoutubeSubscribers,
    TwitterOverview,
    InstagramOverview,
    LinkedinKeyMetrics,
    SocialTrafficMetrics,
    moment,
  },
  data() {
    return {
      flag: "english",
      uId: null,
      medicines: [],
      loader: true,
      future: moment().add(1, "days").format("yyyy-mm-DD"),
      casenum: "",
      ModalText: "Content of the modal",
      visible: false,
      visibleVouched: false,
      visibleCancel: false,
      visibleCancelDelay: false,
      visiblePrompt: false,
      visibleOrder: false,
      visible2: false,
      confirmLoading: false,
      confirmLoadingVouched: false,
      confirmLoadingCancel: false,
      confirmLoadingPrompt: false,
      confirmLoadingOrder: false,
      visibleCancelFromDelay: false,
      confirmLoadingPromptDelay: false,
      confirmLoading2: false,
      shipment_date: "",
      next_shipment: "",
      case_status: "",
      orders: [],
      orderUpdate: {},
      orderUpdateVouched: [],
      orderUpdatePrompt: [],
      orderUpdateCancel: [],
      orderUpdateOrder: [],
      dateFormat: moment,
      fileFaceShot: "",
      filePhotoId: "",
      indicator: <a-icon type="loading" style="font-size: 24px" spin />,
      coupoun_amount: 0,
    };
  },
  methods: {
    onUploadFaceshot(e) {
      this.fileFaceShot = e.target.files[0];
    },
    onUploadPhotoId(e) {
      this.filePhotoId = e.target.files[0];
    },
    onChange(date, dateString) {
      this.shipment_date = dateString;
      console.log(dateString);
    },
    onChangeDate(date, dateString) {
      this.shipment_date = dateString;
      console.log(dateString);
    },
    onChangeCancelDelay(date, dateString) {
      this.shipment_date = dateString;
      console.log(dateString);
    },
    onChange2(date, dateString) {
      this.shipment_date = dateString;
      console.log(dateString);
    },
    disabledDate(current) {
      // Can not select days before today and today

      return current && current.valueOf() < Date.now();
    },
    disabledDate2(current) {
      // Can not select days before today and today
      return current && current.valueOf() > Date.now();
    },
    showModal(order) {
      this.orderUpdate = order;
      this.visible = true;
    },
    showDelayPrompt(order) {
      this.orderUpdatePrompt = order;
      this.visiblePrompt = true;
    },
    openModalVouched(order) {
      this.orderUpdateVouched = order;
      this.visibleVouched = true;
    },
    openModalCancel(order) {
      this.orderUpdateCancel = order;
      this.visibleCancel = true;
    },
    openModalReorder(order) {
      this.orderUpdateOrder = order;
      this.visibleOrder = true;
    },
    showModal2(order) {
      this.orderUpdate = order;
      this.visible2 = true;
    },
    async handleOkOrder() {
      this.confirmLoadingOrder = true;
      let id = this.orderUpdateOrder.id;
      //  let id = 55;
      await axios
        //.get('https://127.0.0.1:8000/api/order/covid/'+ id).then(res => {
        .post("get-covid.php", { id: id })
        .then((res) => {
          console.clear();
          console.log("resp" + res);
          const response = res.data;
          if (response.status) {
            Notification.open({
              message: "Success",
              description: response.message + ".",
              icon: <sdFeatherIcons type="smile" size="14" style="color: #108ee9" />,
            });
          } else {
            Notification.open({
              message: "Failed",
              description: "Couldn't process reorder",
              icon: <sdFeatherIcons type="smile" size="14" style="color: #108ee9" />,
            });
          }
        })
        .catch((err) => {
          console.clear();
          console.log(err);
          Notification.open({
            message: "Couldn't complete request",
            description: "Please contact support",
            icon: <sdFeatherIcons type="smile" size="14" style="color: #108ee9" />,
          });
        });

      this.confirmLoadingOrder = false;
      this.visibleOrder = false;
      this.getMedicine();
    },
    async handleOkCancelActual() {
      this.confirmLoadingCancel = true;
      let id = this.orderUpdateCancel.id;
      //  alert(id)
      await axios
        // .get('https://127.0.0.1:8010/api/cancel-subscription/'+ id).then(res => {//admin url
        .post("get-cancelsubscription.php", { id: id })
        .then((res) => {
          //admin url
          console.clear();
          console.log(res);
          const response = res.data;
          Notification.open({
            message: "Notification",
            description: response,
            icon: <sdFeatherIcons type="smile" size="14" style="color: #108ee9" />,
          });
        })
        .catch((err) => {
          console.clear();
          console.log(err);
          Notification.open({
            message: "Couldn't complete request",
            description: "Please contact support",
            icon: <sdFeatherIcons type="smile" size="14" style="color: #108ee9" />,
          });
        });

      this.confirmLoadingCancel = false;
      this.visibleCancelFromDelay = false;
      this.getMedicine();
    },
    async handleOkCancel() {
      this.confirmLoadingCancel = true;
      let id = this.orderUpdateCancel.id;
      await axios
        // .get('https://127.0.0.1:8010/api/cancel-subscription/'+ id).then(res => {//admin url
        .post("get-cancelsubscription.php", { id: id })
        .then((res) => {
          //admin url
          console.clear();
          console.log(res);
          const response = res.data;
          Notification.open({
            message: "Notification",
            description: response,
            icon: <sdFeatherIcons type="smile" size="14" style="color: #108ee9" />,
          });
        })
        .catch((err) => {
          console.clear();
          console.log(err);
          Notification.open({
            message: "Couldn't complete request",
            description: "Please contact support",
            icon: <sdFeatherIcons type="smile" size="14" style="color: #108ee9" />,
          });
        });

      this.confirmLoadingCancel = false;
      this.visibleCancel = false;
      this.getMedicine();
    },
    async handleOkVouched() {
      this.confirmLoadingVouched = true;
      let id = this.orderUpdateVouched.id;

      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };

      if (this.fileFaceShot.size > 2097152 || this.filePhotoId.size > 2097152) {
        Notification.open({
          message: "Error",
          description: "Filesize is exceeding the limit",
          icon: <sdFeatherIcons type="smile" size="14" style="color: #108ee9" />,
        });
        this.confirmLoadingVouched = false;
        return;
      }
      let data = new FormData();

      data.append("fileFaceShot", this.fileFaceShot);
      data.append("filePhotoId", this.filePhotoId);
      data.append("order_id", id);

      //  await axios.post('https://127.0.0.1:8000/api/upload/documents', data, config)
      await axios
        .post("post-documents.php", data, config)
        .then(function (res) {
          if (res.data.status == true) {
            Notification.open({
              message: "Success",
              description: "The files are uploaded successfully" + ".",
              icon: <sdFeatherIcons type="smile" size="14" style="color: #108ee9" />,
            });
          } else {
            Notification.open({
              message: "Failed",
              description: "Documents can't be uploaded",
              icon: <sdFeatherIcons type="smile" size="14" style="color: #108ee9" />,
            });
          }
          // existingObj.success = res.data.success;
        })
        .catch(function (err) {
          Notification.open({
            message: "Error",
            description: "Network connection issue",
            icon: <sdFeatherIcons type="smile" size="14" style="color: #108ee9" />,
          });
        });
      this.confirmLoadingVouched = false;
      this.visibleVouched = false;

      this.getMedicine();
    },
    async handleOkOrderDelay(e) {
      this.confirmLoadingPromptDelay = true;
      await axios
        .post("post-shipment.php", {
          // .post('https://127.0.0.1:8000/api/customer/shipment', {
          // .post('https://api.getbetterdoctor.com/api/customer/shipment', {

          casenum: this.orderUpdateCancel.casenum,
          shipment_date: this.shipment_date,
        })
        .then((res) => {
          const response = res.data;

          if (response.status === true) {
            this.visibleCancelDelay = false;
            this.confirmLoadingPromptDelay = false;
            this.getMedicine();
            Notification.open({
              message: "Success",
              description: "Shipment Date is Updated" + ".",
              icon: <sdFeatherIcons type="smile" size="14" style="color: #108ee9" />,
            });
          } else {
            this.visibleCancelDelay = false;
            this.confirmLoadingPromptDelay = false;
            Notification.open({
              message: "Error",
              description: "There is error while processing",
              icon: <sdFeatherIcons type="smile" size="14" style="color: #108ee9" />,
            });
          }
        })
        .catch((err) => {});

      setTimeout(() => {
        this.visibleCancelDelay = false;
        this.confirmLoadingPromptDelay = false;
      }, 500);
    },
    async handleOkPrompt(e) {
      this.confirmLoadingPrompt = true;
      await axios
        .post("post-shipment.php", {
          // .post('https://127.0.0.1:8000/api/customer/shipment', {
          //.post('https://api.getbetterdoctor.com/api/customer/shipment', {

          casenum: this.orderUpdatePrompt.casenum,
          shipment_date: this.shipment_date,
        })
        .then((res) => {
          const response = res.data;

          if (response.status === true) {
            this.visiblePrompt = false;
            this.confirmLoadingPrompt = false;
            this.getMedicine();
            Notification.open({
              message: "Success",
              description: "Shipment Date is Updated" + ".",
              icon: <sdFeatherIcons type="smile" size="14" style="color: #108ee9" />,
            });
          } else {
            this.visiblePrompt = false;
            this.confirmLoadingPrompt = false;
            Notification.open({
              message: "Error",
              description: "There is error while processing",
              icon: <sdFeatherIcons type="smile" size="14" style="color: #108ee9" />,
            });
          }
        })
        .catch((err) => {});

      setTimeout(() => {
        this.visiblePrompt = false;
        this.confirmLoadingPrompt = false;
      }, 500);
    },
    async handleOk(e) {
      this.confirmLoading = true;
      await axios
        .post("post-shipment.php", {
          // .post('https://127.0.0.1:8000/api/customer/shipment', {
          casenum: this.orderUpdate.casenum,
          shipment_date: this.shipment_date,
        })
        .then((res) => {
          const response = res.data;

          if (response.status === true) {
            this.visible = false;
            this.confirmLoading = false;
            this.getMedicine();
            Notification.open({
              message: "Success",
              description: "Shipment Date is Updated" + ".",
              icon: <sdFeatherIcons type="smile" size="14" style="color: #108ee9" />,
            });
          } else {
            this.visible = false;
            this.confirmLoading = false;
            Notification.open({
              message: "Error",
              description: "There is error while processing",
              icon: <sdFeatherIcons type="smile" size="14" style="color: #108ee9" />,
            });
          }
        })
        .catch((err) => {});

      setTimeout(() => {
        this.visible = false;
        this.confirmLoading = false;
      }, 500);
    },
    async handleOk2(e) {
      this.confirmLoading2 = true;
      await axios
        .post("post-shipment.php", {
          // .post('https://api.getbetterdoctor.com/api/customer/shipment', {
          casenum: this.orderUpdate.casenum,
          shipment_date: this.shipment_date,
        })
        .then((res) => {
          const response = res.data;

          if (response.status === true) {
            this.visible2 = false;
            this.confirmLoading2 = false;
            this.getMedicine();
            Notification.open({
              message: "Success",
              description: "Shipment Date is Changed" + ".",
              icon: <sdFeatherIcons type="smile" size="14" style="color: #108ee9" />,
            });
          } else {
            this.visible2 = false;
            this.confirmLoading2 = false;
            Notification.open({
              message: "Error",
              description: "There is error while processing",
              icon: <sdFeatherIcons type="smile" size="14" style="color: #108ee9" />,
            });
          }
        })
        .catch((err) => {});

      setTimeout(() => {
        this.visible2 = false;
        this.confirmLoading2 = false;
      }, 2000);
    },
    handleCancel(e) {
      console.log("Clicked cancel button");
      this.visible = false;
    },
    handleCancelVouched(e) {
      console.log("Clicked cancel button");
      this.visibleVouched = false;
    },
    handleCancelOrder(e) {
      console.log("Clicked cancel button");
      this.visibleCancel = false;
    },
    handleCancelOrderDelay(e) {
      console.log("Clicked cancel button");
      this.visibleCancelDelay = false;
    },
    handleCancelPrompt(e) {
      console.log("Clicked cancel button");
      this.visiblePrompt = false;
    },
    handleCancelFromDelay(e) {
      console.log("Clicked cancel button");
      this.visibleCancelDelay = false;
      this.visibleCancelFromDelay = true;
    },
    handleDelayFromDelay(e) {
      console.log("Clicked cancel button");
      this.visibleCancelFromDelay = false;
      this.visibleCancelDelay = true;
    },
    handleCancelOrderFromDelay(e) {
      console.log("Clicked cancel button");
      this.visibleCancelFromDelay = false;
    },
    handleOrderOrder(e) {
      console.log("Clicked cancel button");
      this.visibleOrder = false;
    },
    handleCancelDelay(e) {
      console.log("Clicked cancel button");
      this.visibleCancel = false;
      this.visibleCancelDelay = true;
    },
    handleCancel2(e) {
      console.log("Clicked cancel button");
      this.visible2 = false;
    },

    goToUrl(order) {
      window.location.href =
        "https://my.getbetterdoctor.com/#/app/chat/group/" +
        order.casenum +
        "/" +
        order.treatment.name;
    },
    async getMedicine() {
      this.loader = true;
      await axios
        .get("get-medicines.php")
        // .get('https://127.0.0.1:8000/api/customer/medicines')
        //.get('https://api.getbetterdoctor.com/api/customer/medicines')
        .then((res) => {
          let response = res.data;

          // console.log(response)

          if (response.status === false) {
          }

          if (response.status === true) {
            this.loader = false;
            this.orders = response.orders;
            console.log(this.orders);
            // this.medicines = response.medicines;
            // this.casenum = response.casenum;
            // this.next_shipment = response.next_shipment;
            // this.case_status = response.casestatus;

            // formState.name=  response.billing.name
            //           formState.xr_dosage= response.billing.xr_dosage

            // console.log(this.medicines);
          }
        })
        .catch((err) => {
          console.log("err");

          console.log(err);
        });
    },
  },
  mounted() {
    this.getMedicine();
    setTimeout(() => {
      if (this.orderUpdateOrder?.coupon_amount != null || "NaN") {
        this.coupoun_amount = this.orderUpdateOrder?.coupon_amount;
      } else {
        this.coupoun_amount = 0;
      }
    }, 2000);
  },
  async setup() {
    let medicines = [];
    //     await  axios.get('https://my.better-doctor.com/api/api/customer/medicines/1').then( res => {
    //     let  response = res.data
    //       // console.log(response.medicines)

    //     if(response.status === false){

    //     }

    //     if(response.status === true){
    //       medicines = response.medicines

    // // formState.name=  response.billing.name
    // //           formState.xr_dosage= response.billing.xr_dosage

    //       console.log(medicines)
    // }

    //      }).catch( err => {
    //       console.log('err')

    //       console.log(err)
    //     })

    //     return {

    //     medicines
    //     };
  },
};
</script>
<style>
.ant-notification-notice {
  background-color: #0db0b2 !important;
}
.spinner {
  background-color: transparent !important;
  border: transparent !important;
}
.ant-btn-primary {
  border-color: #0db0b2 !important;
}
.ant-calendar-footer {
  display: none;
}
.warning {
  padding-top: 0.2rem;
  color: #fa8b0c;
  font-size: 0.6rem;
}
.med {
  position: absolute;
  top: 27px;
  right: 37px;
}
.bp {
  position: absolute;
  top: 4px;
  right: 86px;
}
.footer {
  min-height: calc(100vh - 114px);
}
@media (max-width: 480px) {
  .med {
    width: 75px;
    top: 16%;
  }
  .bp {
    right: 18%;
    height: 36px;
    top: 7%;
  }
}
@media (max-width: 370px) {
  .med {
    width: 55px;
    right: 8px;
    top: 15%;
  }
  .bp {
    right: 3%;
    height: 36px;
    top: 7%;
  }
}

/* @media (min-width: 321px) and (max-width: 364px) {
  .row {
    width: 107%;
  }

  .chat {
    margin-left: 2%;
    margin-top: 10px;
  }
  .buttonsooner {
    margin-left: 1% !important;
  }
  .button {
    margin-left: 1%;
  }

  .buttondelay {
    margin-left: -9% !important;
  }
  .ant-card-bordered {
    border: 1px solid #f0f0f0;
  }
  .bp {
    background-image: url('https://migrationade.com/t_pill.png');
    background-size: cover;
    max-height: 22px;
    max-width: 66px;
    background-color: white;
    display: block;
    margin-top: -46%;
    margin-left: 79%;
  }
  .med {
    padding-top: 18%;
    height: 58px;
  }
} */

.ant-btn-primary {
  background-color: #0db0b2 !important;
}
.modal-header {
  text-align: center;
  font-weight: bold;
  color: #6d6868;
}
.modal-header-inner {
  text-align: left;
  padding-top: 0.2rem;
  color: #6d6868;
}
/* .bp {
  background-image: url('https://migrationade.com/t_pill.png');

  background-size: cover;
  max-height: 46px;

  max-width: 66px;

  background-color: white;
  display: block;
} */
/* .med {
  padding-top: 30%;
} */

.spinclass {
  margin-top: 300px;
  margin-left: 800px;
}
.ant-spin-dot-item {
  background-color: rgb(13, 179, 181) !important;
}
.loader {
  border: 10px solid #0db0b2;
  border-radius: 50%;
  border-top: 4px solid white;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}
.loaderstyle {
  align-content: center;
  padding-top: 20%;
  padding-left: 40%;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
<style>
.ant-spin-dot-item {
  background-color: #0db0b2 !important;
}
</style>
